import React from "react"

import Layout from "../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faLinkedin,
  faGitlab,
  faGithub
} from "@fortawesome/free-brands-svg-icons";
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Joshua Barkovic" />
    <img href="./images/profile-square.jpg"></img>
    <h2>Projects</h2>
    <div>
      <h3>Google Summer of Code - Eclipse Foundation - SWT Wayland Port</h3>
      <img src="https://www.phoronix.net/image.php?id=0x2014&image=eclipse_wayland_weston"></img>
      <p>Ported the Standard Widget Toolkit (SWT) to the Wayland display server protocol in 2014</p>
      <a href="https://www.phoronix.com/news/MTc5MDM">Coverage on phoronix</a>
    </div>
    <a style={{
      height: 50,
      width: 50,
      padding: 10
    }} href="https://www.linkedin.com/in/jbarkovic/"><FontAwesomeIcon style={{
      height: 50,
      width: 50
    }} icon={faLinkedin}/></a>
    <a style={{
      height: 50,
      width: 50,
      padding: 10
    }} href="https://gitlab.com/joshbarkovic"><FontAwesomeIcon style={{
      height: 50,
      width: 50
    }} icon={faGitlab}/></a>
    <a style={{
      height: 50,
      width: 50,
      padding: 10
    }} href="https://github.com/jbarkovic"><FontAwesomeIcon style={{
      height: 50,
      width: 50,
      color: 'black'
    }} icon={faGithub}/></a>
  </Layout>
)

export default IndexPage
